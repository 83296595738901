<template>
  <svg
    viewBox="0 0 74 57"
    xmlns="http://www.w3.org/2000/svg"
    width="74"
    height="57"
  >
    <path
      d="M37.0002 7.00114C25.5212 7.00114 14.445 11.2319 5.88908 18.8845C4.51692 20.1118 2.40964 19.9944 1.18234 18.6222C-0.0449579 17.2501 0.0724768 15.1428 1.44464 13.9155C11.2228 5.16962 23.8814 0.334473 37.0002 0.334473C50.119 0.334473 62.7776 5.16962 72.5558 13.9155C73.9279 15.1428 74.0454 17.2501 72.8181 18.6222C71.5908 19.9944 69.4835 20.1118 68.1113 18.8845C59.5554 11.2319 48.4791 7.00114 37.0002 7.00114Z"
    />
    <path
      d="M16.0002 32.7138C21.6081 27.217 29.1476 24.1381 37.0002 24.1381C44.8528 24.1381 52.3923 27.217 58.0002 32.7138C59.3149 34.0025 61.4253 33.9814 62.714 32.6667C64.0027 31.352 63.9816 29.2415 62.6669 27.9528C55.8128 21.2345 46.5978 17.4714 37.0002 17.4714C27.4026 17.4714 18.1876 21.2345 11.3335 27.9528C10.0188 29.2415 9.99772 31.352 11.2864 32.6667C12.5751 33.9814 14.6855 34.0025 16.0002 32.7138Z"
    />
    <path
      d="M27.6669 44.3805C30.1593 41.9375 33.5102 40.569 37.0002 40.569C40.4902 40.569 43.8411 41.9375 46.3335 44.3805C47.6482 45.6691 49.7587 45.648 51.0473 44.3333C52.336 43.0186 52.3149 40.9082 51.0002 39.6195C47.2616 35.955 42.2353 33.9024 37.0002 33.9024C31.7651 33.9024 26.7388 35.955 23.0002 39.6195C21.6855 40.9082 21.6644 43.0186 22.9531 44.3333C24.2417 45.648 26.3522 45.6691 27.6669 44.3805Z"
    />
    <path
      d="M37.0002 50.3334C35.1592 50.3334 33.6669 51.8257 33.6669 53.6667C33.6669 55.5076 35.1592 57 37.0002 57H37.0335C38.8745 57 40.3669 55.5076 40.3669 53.6667C40.3669 51.8257 38.8745 50.3334 37.0335 50.3334H37.0002Z"
    />
  </svg>
</template>
